@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

@tailwind base;
@tailwind components;
@tailwind utilities;

.servlinear {
    background: linear-gradient(272.09deg, #9A45B8 -40.64%, #0095C3 97.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.bluelinear {
    background: linear-gradient(91.46deg, #1785C0 -11.75%, #0CC0DB 58.06%, #9A45B8 116.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}


.effect {
    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(6.8px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 45px;
    margin-right: 51px;
}

@font-face {
    font-family: 'Fontspring-normal';
    src: url('./assets//fonts/Fontspring-DEMO-articulatcf-/Fontspring-DEMO-articulatcf-normal.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.Fontspring-normal {
    font-family: 'Fontspring-normal';

}



@font-face {
    font-family: 'Gilroy-Bold';
    src: url('./assets//fonts/Gilroy/Gilroy-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.Gilroy-Bold {
    font-family: 'Gilroy-Bold';

}



@font-face {
    font-family: 'Gilroy-Medium';
    src: url('./assets//fonts/Gilroy/Gilroy-Medium.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.Gilroy-Medium {
    font-family: 'Gilroy-Medium';

}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('./assets//fonts/Gilroy/Gilroy-Medium.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}


.Gilroy-Regular {
    font-family: 'Gilroy-Regular';

}




@font-face {
    font-family: 'Gilroy-Light';
    src: url('./assets//fonts/Gilroy//Gilroy-Light.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.Gilroy-Light {
    font-family: 'Gilroy-Light';

}



@font-face {
    font-family: 'AltoneTrial-Regular';
    src: url('./assets//fonts/AltoneTrial/AltoneTrial-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.AltoneTrial-Regular {
    font-family: 'AltoneTrial-Regular';

}


@font-face {
    font-family: 'Fontspring-reqular';
    src: url('./assets//fonts/Fontspring-DEMO-articulatcf-/Fontspring-DEMO-articulatcf-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.Fontspring-reqular {
    font-family: 'Fontspring-reqular';

}


@font-face {
    font-family: 'EuclidCircular';
    src: url('./assets//fonts/Ecluid_circular/EuclidCircularegular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.EuclidCircular {
    font-family: 'EuclidCircular';

}


@font-face {
    font-family: 'EuclidCircularMedium';
    src: url('./assets//fonts/Ecluid_circular/EuclidCircularMedium.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.EuclidCircularMedium {
    font-family: 'EuclidCircularMedium';

}


@font-face {
    font-family: 'EuclidCircularbold';
    src: url('./assets//fonts/Ecluid_circular/EuclidCircularegular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.EuclidCircularbold {
    font-family: 'EuclidCircularbold';

}



.linear-service {
    /* Service Craft */
    letter-spacing: -0.01em;
    background: linear-gradient(272.09deg, #9A45B8 -40.64%, #0095C3 97.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}


.linear-excellence {
    /* Service Craft */
    letter-spacing: -0.01em;
    background: linear-gradient(272.09deg, #0CC0DB -40.64%, #00A5D8 97.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

}




.linear-creating {
    /* Creating the Art of Stylish Living */

    background: linear-gradient(91.85deg, #0CC0DB 0.27%, #6E5BBB 98.43%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;



}



.craft-gradient {
    /* Service Craft */
    background: linear-gradient(272.09deg, #9A45B8 -40.64%, #0095C3 97.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;



}

.linear-client {
    /* Clinet’s are */

    letter-spacing: -0.03em;

    background: linear-gradient(93.44deg, #6E5BBB 0.92%, #0B8DC1 52.68%, #4963BA 70.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;




}


.linear-email {

    letter-spacing: -0.04em;

    background: linear-gradient(91.46deg, #1785C0 -11.75%, #0CC0DB 58.06%, #9A45B8 116.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


}

.linear-menu {

    /* Bluetikinteriors.com */

    letter-spacing: -0.04em;

    background: linear-gradient(91.46deg, #1785C0 -11.75%, #0CC0DB 58.06%, #9A45B8 116.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;



}

.ReactModal__Content.ReactModal__Content--after-open {
    border: none !important;
}

.slick-slide img {
    display: block;
    margin-left: 57px;
}

.slick-prev {
    left: -30px;
}

.slick-next {
    right: -30px;
}

.slick-prev,
.slick-next {
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 16px;
}

/* Bluetikinteriors.com */
/* Rectangle 232 */

.glassbg {

    background: rgba(0, 0, 0, 0.28);
    backdrop-filter: blur(6.8px);
    /* Note: backdrop-filter has minimal browser support */
    /* border-radius: 0px 0px 33px 33px; */
    font-size: 27px;
    color: #EFB113;
    text-align: center;
}


/* Client's */

.client-gradient {
    letter-spacing: -0.03em;

    background: linear-gradient(93.44deg, #6E5BBB 0.92%, #0B8DC1 52.68%, #4963BA 70.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}



.bottom-10 {
    bottom: 6.5rem;
}


@media (max-width: 768px) {
    .hide-on-mobile {
        display: none;
    }
}


@media (max-width: 1024px) {
    .navbar-menu {
        display: none;
    }

    .navbar-menu.active {
        display: block;
    }
}

@media (max-width: 430px) {
    .slick-slide img {
        display: block;
        margin-left: 1px;
    }


    .ReactModal__Overlay.ReactModal__Overlay--after-open {

        background-color: rgb(36 36 36 / 75%) !important;
    }
}

/* Add this CSS */
.mobile-only {
    display: block;
}

@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}